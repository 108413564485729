import React from 'react'
import { Helmet } from 'react-helmet'
import Banner from '../components/Banner'
import { CouncilCard } from '../components/CouncilCard'
import Layout from '../components/Layout'
import developerData from '../data/developers'

const Developer = () => {
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>PICT ACM Student Chapter | Developers</title>
          <meta name="description" content="PICT ACM Student Chapter(PASC), a student chapter organization subsidiary of the Association for Computing Machinery(ACM), consists of highly motivated students, ready to learn and help each other bring the best out of them. PASC began in 2011, with the perspective of fostering technical and non-technical qualities in an individual and helping them to shape their future." />
      </Helmet>
      <Banner
        title="Developers"
        text={`We are an Award-Winning team. Our team consists of technically sound members that transform novel ideas into real-life experiences. We believe in a pragmatic approach and commit to quality and end-to-end processes. With a high level of confidence and integrity, we believe in contributing to the real-world web production environment.`}
        image="team.png"
      />
      <div className={`py-10 px-4 sm:px-8 md:px-12 lg:px-16 grid md:grid-cols-2 xl:grid-cols-3 gap-8`}>
        {developerData.map((member, i) => (
            <CouncilCard details={member} key={i} />
          )
        )}
      </div>
    </Layout>
  )
}

export default Developer
